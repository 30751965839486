div.tenant-page {
  padding: 234px 24px 24px 24px;

  .breadcrumbs {
    width: 100%;
    position: fixed;
    top: 63px;
    left: 0;
    background-color: #fff;
    z-index: 2;
  }

  div.header-buttons {
    display: flex;
    .delete-btn {
      margin-right: 8px;
    }
  }
}

.spinner-tenant {
  margin-top: 250px;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;

  .popup-btn {
    margin-right: 8px;
  }
}

.popup-question {
  display: flex;
  align-items: center;

  .anticon {
    width: 18px;
    height: 18px;
    margin-left: 0;

    svg {
      width: 18px;
      height: 18px;
      color: #fff;
      background-color: #faad14;
      border-radius: 100%;
    }
  }

  span {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}
