@import "./core/fonts.scss";

body {
  font-family: "Poppins";
  background-color: #f1f2f5;
}

ul {
  list-style: none;
}

#root {
  height: 100%;
  background-color: #f0f2f5;
}

.app {
  height: 100%;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
