.plugins-tab {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 24px;

  & > div:first-child {
    width: 34%;
  }

  & > div:last-child {
    width: 66%;
  }
}

.card-body {
  padding: 24px;

  .plugins-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    .info {
      display: flex;
      align-items: center;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #40a9ff;
      margin-right: 16px;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      font-size: 16px;
      color: #fff;
    }

    .user-attr {
      background-color: #ff7a45;
    }

    .data-attr {
      background-color: #ffa940;
    }

    .auth {
      background-color: #597ef7;
    }
  }

  .item-selected {
    background-color: #e6f7ff;
  }
}

div.plugin-card {
  border: 1px solid #d9d9d9;
}

div.plugins-tab-list {
  border: 1px solid #d9d9d9;

  .search-wrapper {
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
  }
}

.plugins-tab-descr-extra {
  display: flex;
  align-items: center;

  .enable {
    display: flex;
    height: 40px;
    align-items: center;
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #d9d9d9;

    span {
      margin-right: 8px;
    }
  }
}

div.plugins-tab-description {
  border: 1px solid #d9d9d9;

  .card-head {
    padding: 24px;
    color: rgba(0, 0, 0, 0.65);
  }

  .card-body {
    background: #fafafa;
    padding: 24px;
    border-top: 1px solid #f0f0f0;
  }
}

.plugins-tab-select {
  width: 111px;
}
